<template>
  <div>
    <v-autocomplete :value="newValue"
                    :items="options"
                    :loading="loading"
                    :search-input.sync="searchInput"
                    @update:search-input="seekInput"
                    @change="updateValue"
                    :hide-no-data="!searchInput || searchInput.length < 3 || loading"
                    item-text="formatted"
                    item-value="formatted"
                    placeholder="Type to Search"
                    outlined
                    :clearable="false"
                    :error="errors && errors.length > 0"
                    :class="errors && errors.length ? 'hasError' : ''"
                    no-filter
                    hide-details
                    return-object
                    :disabled="disabled || false"
                    no-data-text="No Results Found"
                    :label="required ? label + '*' : label">
        <template v-slot:append><v-icon class="error-icon" v-if="errors && errors.length">mdi-alert</v-icon></template>
    </v-autocomplete>
    <ErrorMessages :errors="errors"></ErrorMessages>
  </div>
</template>

<script>
  import { UspsService } from '@/api/UspsService'
  import { sanitizeSeek } from '@/scripts/helper'

  export default {
    name: "CityStateZipAutocompleteV1",
    components: {
    },
    data() {
      return {
        newValue: null,
        options: [],
        timeout: null,
        searchInput: null,
        loadingCount: 0
      }
    },
    props: {
      value: Object,
      rules: String,
      label: {
        type: String,
        default: "City / State / Zip Code"
      },
      clearable: Boolean,
      required: Boolean,
      errors: Array,
      disabled: Boolean,
      name: String,
      module: String
    },
    emits: [
      'change'
    ],
    methods: {
      updateValue(v) {
        if (v) {
          this.newValue = v;
        } else {
          this.newValue = null;
        }

        this.emitValue();
      },
      emitValue() {
        this.$emit('change', this.newValue)
      },
      seekInput(q) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.seek(q);
        }, 300)
      },
      seek(q) {
        q = sanitizeSeek(q);

        if (this.newValue && q == this.newValue.formatted) return;

        if (q && q.length > 2) {
          this.loadingCount++;

          UspsService.cityStateZipSeek({ q: q })
            .then((data) => {
              this.$nextTick(() => {
                this.options = data.map(o => { return o });
              });
            }).catch(() => {
            }).finally(() => {
              this.loadingCount--;
            });

        }
      },
    },
    computed: {
      loading() {
        return this.loadingCount > 0;
      }
    },
    watch: {
      value: {
        immediate: true,
        deep: true,
        handler(newVal) {
          if (this.newValue != newVal) {
            this.newValue = newVal;
            if (this.newValue.state.length == 2) {
              this.options.push(this.newValue);
            }
          }
        }
      }
    },
  }
</script>
